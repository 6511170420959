.grid {
  display: grid;

  grid-template:
    " sidebar heading   ."  64px
    " sidebar main      ."  auto
    " toolbar inputBar  ."  64px
    / 360px   auto       360px;

  column-gap: 16px;

  background-color: #000000;

  width: 100vw;
  height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
}

.sidebar,
.main {
  padding-right: 16px;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: #202020;
  margin: 16px 0;
}

::-webkit-scrollbar-thumb {
  background-color: #808080;
}

.sidebar {
  grid-area: sidebar;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  overflow: auto;
}

.chats {
  max-height: calc(100vh - 64px);
}

.chatList {
  width: 100%;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.chatListTitle {
  color: #808080;
  text-transform: uppercase;

  margin-bottom: 8px;
}

.currentChatHeading {
  grid-area: heading;
}

.main {
  grid-area: main;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  overflow: auto;
}

.currentChat {
  max-height: calc(100vh - 128px);
}

.currentChatHeadingImage {
  background-color: #202020;
  border-radius: 8px;

  width: 48px;
  height: 48px;
  margin: 8px 0;
  margin-right: 8px;
  padding: 8px;
  float: left;
  box-sizing: border-box;
}

.currentChatHeadingTitle {
  font-size: 16px;
  line-height: 64px;

  float: left;
}

.messageThread {
  margin-bottom: 16px;
}

.messageAuthor {
  color: #a0a0a0;
  // font-size: 16px;

  margin-bottom: 4px;
}

.messageText {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;

  overflow: auto;
}

.issue {
  background-color: #101010;
  border: 1px solid #202020;
  border-radius: 8px;

  margin: 4px 0 8px;
  padding: 12px 16px 8px;
  overflow: auto;
}

.issue:last-child {
  margin-bottom: 0;
}

.issueTitle {
  color: #a0a0a0;
  margin-bottom: 8px;
}

.issueCommentInput {
  background-color: #202020;
  color: #f0f0f0;
  border: 2px solid transparent;
  border-radius: 16px;

  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;

  width: 100%;
  margin: 0;
  margin-bottom: 8px;
  padding: 6px 10px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border: 2px solid #808080;
  }

  &::placeholder {
    color: #a0a0a0;
  }
}

.issueLink {
  color: #808080;
  text-transform: uppercase;
  font-size: 12px;
}

.toolbar {
  grid-area: toolbar;
}

.inputBar {
  grid-area: inputBar;

  // background-color: #202020;
}

.messageInput {
  background-color: #202020;
  color: #f0f0f0;
  border: 2px solid transparent;
  border-radius: 24px;

  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;

  width: calc(100% - 16px);
  margin: 8px 8px;
  padding: 14px 18px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border: 2px solid #808080;
  }

  &::placeholder {
    color: #a0a0a0;
  }
}
