body {
  background-color: #000000;
  color: #f0f0f0;

  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 16px;

  margin: 0;
  padding: 0;
}
