.ChatListProjectItem {
  display: grid;

  grid-template:
    " icon  title"    24px
    " icon  content"  auto
    " icon  controls" auto
    / 48px  auto;

  column-gap: 8px;

  cursor: pointer;
  min-height: 48px;
  margin-bottom: 8px;
  overflow: auto;

  &__icon {
    grid-area: icon;

    background-color: #202020;
    border-radius: 8px;

    width: 48px;
    height: 48px;
    padding: 8px;

    box-sizing: border-box;
  }

  &__title {
    grid-area: title;

    color: #a0a0a0;

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin: 4px 0;
  }

  &__issueList {
    grid-area: content;

    cursor: default;
    width: 100%;
    overflow-x: hidden;
  }

  &__issue {
    background-color: #101010;
    border: 1px solid #202020;
    border-radius: 8px;
    cursor: pointer;
  
    margin-bottom: 8px;
    padding: 8px 16px 8px;
    overflow: auto;
    box-sizing: border-box;
  }

  &__issueTitle {
    color: #f0f0f0;

    font-size: 14px;
    line-height: 20px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin-bottom: 8px;
  }

  &__issueLink {
    color: #808080;
    text-transform: uppercase;
    font-size: 12px;
  }

  &__controls {
    grid-area: controls;
  }

  &__button {
    background-color: #000000;
    color: #808080;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    line-height: 16px;

    margin-left: 8px;
    padding: 6px 10px;

    float: right;
  }

  &:hover {
    .ChatListProjectItem {
      &__icon {
        box-shadow: inset 0 0 0 2px #808080;
      }

      &__title {
        text-decoration: underline;
      }

      &__button {
        background-color: #202020;
        border: 2px solid #808080;
        color: #a0a0a0;
        text-decoration: underline;
      }
    }
  }

  &__issueList:hover ~ &__icon {
    box-shadow: none;
  }

  &__issueList:hover ~ &__title {
    text-decoration: none;
  }

  &__issueList:hover ~ &__controls &__button {
    background-color: #101010;
    border: 2px solid #202020;
    text-decoration: none;
  }

  &__issue:hover {
    background-color: #202020;
    border: 1px solid #808080;
  }

  &__issue:hover &__issueLink {
    color: #a0a0a0;
    text-decoration: underline;
  }
}
