.ChatListPersonItem {
  display: grid;

  grid-template:
    " avatar  title"    24px
    " avatar  content"  auto
    / 48px    auto;

  column-gap: 8px;

  min-height: 48px;
  margin-bottom: 8px;
  overflow: auto;

  &__avatar {
    grid-area: avatar;

    width: 48px;
    height: 48px;
    border-radius: 24px;
    // margin-right: 8px;
  
    float: left;
  }

  &__title {
    grid-area: title;

    color: #a0a0a0;
    margin: 4px 0;
  
    float: left;
  }

  &__inlineChat {
    grid-area: content;
  }

  &__message {
    background-color: #202020;
    border-bottom: 2px solid #303030;

    font-size: 14px;
    line-height: 16px;

    padding: 8px 12px;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 16px;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      margin-bottom: 8px;
    }
  }

  &__messageInput {
    background-color: #202020;
    color: #f0f0f0;
    border: 2px solid transparent;
    border-radius: 16px;
    border-bottom-right-radius: 0;

    margin: 0;
    margin-bottom: 8px;
    padding: 6px 10px;
    
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    line-height: 16px;

    width: 100%;
    resize: none;
    display: block;
    box-sizing: border-box;

    &:focus {
      outline: none;
      border: 2px solid #808080;
      border-bottom-right-radius: 4px;
    }

    &::placeholder {
      color: #c0c0c0;
    }
  }

  &__button {
    background-color: #000000;
    color: #808080;
    border: 2px solid transparent;
    border-radius: 8px;
    
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    line-height: 16px;

    margin-left: 8px;
    padding: 6px 10px;

    float: right;
  }

  &__messageSubmit {
    background-color: #202020;
    color: #f0f0f0;
    border: 2px solid #808080;

    display: none;

    &_visible {
      display: block;
    }
  }

  &__messageInput:focus ~ &__messageSubmit {
    display: block;
  }
}
